@import url(https://fonts.googleapis.com/css?family=Amatic+SC:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:700|Teko);
@import url(https://fonts.googleapis.com/css?family=Oranienbaum);
@import url(https://fonts.googleapis.com/css?family=Nixie+One|Oranienbaum);
@import url(https://fonts.googleapis.com/css?family=Cormorant+SC|Faster+One|Six+Caps|Wire+One);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+HK&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ------------------------------------------------------------------------------------------------------------------ */

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  /* -webkit-overflow-scrolling: touch; */
  height: 100%;
  font-size: 16px;
}

body {
  /* -webkit-overflow-scrolling: touch; */
  font-family: "Noto Sans HK", sans-serif;
  height: 100%;
  background: black;
}

h1 {
  font-size: 3rem;
  letter-spacing: 2px;
  margin: 0;
  color: white;
}

h1.welcome{
  letter-spacing: 0;
  font-size: 3rem;
  font-family: "Kanit", sans-serif;
  font-weight: 300;
}

@media (min-width: 1024px) {
  h1 {
    font-size: 2rem;
  }
}

h3 {
  font-size: 2rem;
  letter-spacing: 4px;
}

h2 {
  font-family: "Wire One";
  font-size: 4rem;
  color: white;
  margin: 0;
}

h2.organ{ /*Mobile*/
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-size: 2rem;
}

@media (min-width: 1024px) { /*PC*/
  h2.organ {
    font-size: 3rem;
  }
}

h2.team {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  text-shadow: 
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
}

@media (min-width: 1024px) { /*PC*/
  h2.team {
    font-size: 3rem;
  }
}

h1,
h3 {
  font-family: "Wire One";
  text-shadow: 5px 5px 5px black;
}

h4 {
  font-family: "Tilt Neon", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  text-shadow: 5px 5px 5px black;

  color: white;
  font-size: 1rem;
}

@media (min-width: 1024px) {
  h4 {
    font-size: 1rem;
  }
}

p {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  /* text-shadow: 5px 5px 3px black; */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  opacity: 0.8;
  border-bottom: 2px solid beige;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 70px;
  padding: 10px;
  box-shadow: 0px 10px 15px black;
}

.App {
  text-align: center;
}

a {
  font-size: 2rem;
}

section {
  padding: 3rem 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 1024px) {
  section {
    padding: 9rem 3rem;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 840px;
  width: 100%;
}

.content-container > * {
  margin: 2rem 0;
  max-width: 100%;
}

.logo {
  max-width: 100%;
}

.trailer {
  max-height: 240px;
  box-shadow: 0px 10px 15px black;
}

@media (min-width: 768px) {
  .trailer {
    max-height: none;
  }
}

@media (min-width: 1024px) {
  .game-gif {
    /* height: 540px; */
    box-shadow: 0px 10px 15px black;
  }
}

.organ-ost {
  width: 100%;
  height: 300px;
  box-shadow: 0px 10px 15px black;
}

@media (min-width: 1024px) {
  .organ-ost {
    margin-left: 70px;
    width: 700px;
    height: 400px;
    box-shadow: 0px 10px 15px black;
  }
}

.itch {
  box-shadow: 0px 10px 15px black;
}

.black-border {
  border: solid 2px black;
}

/* ------------------------------------------------------------------------------HEADER TOP LINKS  */

.main {
  margin-top: 70px;
}

.link-container {
  width: 100%;
}

.link-button {
  margin: 5px;
  font-size: 1rem;
  font-family: "Teko";
  background-color: black;
  color: white;
  padding: 6px 12px 5px 12px;
  width: 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  color: beige;
}

.link-button:active {
  background-color: aqua;
  box-shadow: none;
  top: 5px;
  color: grey;
}

.right-side-links {
  display: none;
}

.copyright {
  font-family: "Teko";
  margin: 10px;
  font-size: 1rem;
  color: rgb(211, 198, 198);
  margin-top: 100px;
  z-index: 2;
}

@media (min-width: 1024px) {
  .copyright {
    font-size: 1rem;
  }
}

.facebook-link {
  margin: 5px;
  font-size: 3rem;
  z-index: 1;
  color: rgb(110, 135, 154);
}

.twitter-link {
  margin: 5px;
  font-size: 3rem;
  z-index: 1;
  color: rgb(110, 135, 154);
}

.steam-link {
  margin: 5px;
  font-size: 3rem;
  z-index: 1;
  color: rgb(110, 135, 154);
}

.discord-link {
  margin: 5px;
  font-size: 2.8rem;
  z-index: 1;
  color: rgb(110, 135, 154);
}

.mail-link {
  margin: 5px;
  font-size: 3rem;
  z-index: 1;
  color: rgb(110, 135, 154);
}

.twitter-link:hover,
.facebook-link:hover,
.steam-link:hover,
.discord-link:hover,
.mail-link:hover {
  margin: 5px;
  font-size: 3rem;
  z-index: 1;
  color: aqua;
}

.discord-link:hover {
  font-size: 2.8rem;
}

.mail-link:hover {
  font-size: 3rem;
}

.game-description-div {
  background: black;
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: solid 2px grey;
  border-bottom: solid 2px grey;
}

.game-description {
  margin-top: 8px;
  color: white;
  margin-bottom: 10px;
  padding: 10px;
}

/* ---------------------------------Anchors ⚓⚓️⚓️⚓️ */

.team-anchor {
  display: block;
  position: relative;
  top: -75px;
  visibility: hidden;
}

.organ-anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

.blood-anchor {
  display: block;
  position: relative;
  top: -40px;
  visibility: hidden;
}

@media (min-width: 1024px) {
  .blood-anchor {
    display: block;
    position: relative;
    top: 60px;
    visibility: hidden;
  }
}

.guilt-anchor {
  display: block;
  position: relative;
  top: -40px;
  visibility: hidden;
}

.roadmap-anchor {
  display: block;
  position: relative;
  top: -75px;
  visibility: hidden;
}

@media (min-width: 1024px) {
  .guilt-anchor {
    display: block;
    position: relative;
    top: 60px;
    visibility: hidden;
  }
}

.home-anchor {
  display: block;
  position: relative;
  top: -300px;
  visibility: hidden;
}

/* ----------------------------------- */

/* -----------------------------------------------------------------------------------------------MEDIA QUERY 1024 🌏🌏 */
/* -----------------------------------------------------------------------------------------------MEDIA QUERY 1024 🌏🌏*/
/* -----------------------------------------------------------------------------------------------MEDIA QUERY 1024 🌏🌏*/
/* -----------------------------------------------------------------------------------------------MEDIA QUERY 1024 🌏🌏*/

@media (min-width: 1024px) {
  h3 {
    font-size: 4rem;
  }

  .game-description-div {
    margin-top: -20px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
  }

  .game-description {
    text-align: center;
    width: 50%;
    color: white;
    padding: 25px;
    background: none;
    border-bottom: none;
  }

  /* -------------------------Links */

  .link-container {
    width: 30%;
    text-align: left;
  }

  .link-button:hover,
  .home-button:hover {
    background-color: aqua;
    cursor: pointer;
    color: black;
  }

  .right-side-links {
    display: inline;
  }

  .facebook-link {
    margin: 5px;
    font-size: 3rem;
    z-index: 1;
    color: rgb(110, 135, 154);
  }

  .steam-link {
    margin: 5px;
    font-size: 3rem;
    z-index: 1;
    color: rgb(110, 135, 154);
  }

  .facebook-link:hover,
  .steam-link:hover {
    margin: 5px;
    font-size: 3rem;
    z-index: 1;
    color: aqua;
  }

}

/* --------------------------------------------------------------------------------ANIMATIONS */

.fade-in-element {
  -webkit-animation: fade-in 1.4s;
          animation: fade-in 1.4s;
}

.pulse {
  -webkit-animation: pulse 1.5s infinite;
          animation: pulse 1.5s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0.7, 0.7);
            transform: scale(0.7, 0.7);
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0.7, 0.7);
            transform: scale(0.7, 0.7);
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
  50% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
  }
  100% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
  50% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
  }
  100% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
}




.team-title {
  background-image: url("https://i.imgur.com/MU0NA3k.png");
  height: 600px;
}

@media (min-width: 1024px) {
  .team-title {
    height: 800px;
  }
}

.name-highlight {
    color: aqua;
}

.foot-link {
  font-size: 1rem;
  color: rgb(211, 198, 198);
}

.blood-title {
  background-image: url("/public/assets/titleBG.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center;
}


.blood-content {
  background-image: url("/public/assets/BloodTypersContentBG.png");
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center;
}



.organ-title {
    background-image: url("https://i.imgur.com/v6SA7ew.jpg");
}

.organ-content {
    background-image: url("https://i.imgur.com/Okm6DRZ.png");
}
.guilt-title {
  background-image: url("https://i.imgur.com/1EaaJtN.gif");
}


.guilt-content {
  background-image: url("https://i.imgur.com/NhUw4v9.png");
}


/*
  --------------------------------------------------------------WELCOME PAGE
  */
.welcome-title {
  background-image: url("https://i.imgur.com/MU0NA3k.png");
  margin-top: 0;
}

@media (min-width: 1024px) {
  .welcome-title {
    height: 800px;
  }
}

.outerbrain-logo {
  margin-top: 0;
  width: 320px;
  max-width: 100%;
  -webkit-animation: pulse 1.5s infinite;
          animation: pulse 1.5s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@media (min-width: 1024px) {
  .outerbrain-logo {
    width: 500px;
    margin-top: -100px;
    
  }
}

.fade-in-element {
  -webkit-animation: fade-in 1.4s;
          animation: fade-in 1.4s;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-image: url("https://i.imgur.com/MU0NA3k.png");
}

p {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: #ffffff;
    /* text-shadow: 5px 5px 3px black; */
}

.color-warning {
    color: #989595;
}

a {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: #ffffff;
}

.bold{
    font-weight: bold;
}

h1 {
    font-family: "Kanit", sans-serif;
}
