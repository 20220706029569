html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-image: url("https://i.imgur.com/MU0NA3k.png");
}

p {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: #ffffff;
    /* text-shadow: 5px 5px 3px black; */
}

.color-warning {
    color: #989595;
}

a {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: #ffffff;
}

.bold{
    font-weight: bold;
}

h1 {
    font-family: "Kanit", sans-serif;
}